import { Route, Routes } from "react-router-dom";
import { lazy, useEffect } from "react";
import { useDispatch } from "react-redux";

import Setting from "../page/Setting";
import { login } from "./../store/action/auth/index";
const Main = lazy(() => import("../page/Step/Main"));
const Vote = lazy(() => import("../page/Vote"));

export const Router = ({ mode, changeMode }) => {
  // localStorage.setItem("token", process.env.REACT_APP_Token);

  const dispatch = useDispatch();

  const fetchLogin = async ({ countryCode, mobile, password }) => {
    await login({
      mobile: countryCode + mobile,
      password,
    })(dispatch);
  };

  useEffect(() => {
    fetchLogin({
      countryCode: "20",
      mobile: "1099093619",
      password: "123456789",
    });
  }, []);

  return (
    <Routes>
      <Route path="/Main" element={<Main />} />
      <Route path="/setting" element={<Setting />} />

      <Route path="/*" element={<Vote />} />
    </Routes>
  );
};

export default Router;
