import { BrowserRouter } from "react-router-dom";
import { Box, Fade } from "@mui/material";
import Router from "./Router";

import { useCallback } from "react";

import { useState } from "react";
// import { top, logoSpach, bottom, logo } from "./Assets";

export default function Splash({ colorMode, mode, isRTL }) {
  const path = window.location.pathname;

  return (
    <>
      {/* { <PyxizSplash />} */}
      <Box
        sx={{
          position: "absolute",
          zIndex: -9,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "background.secondary",
        }}
      ></Box>
      <BrowserRouter>
        <Router changeMode={colorMode} mode={mode} isRTL={isRTL} />
      </BrowserRouter>
    </>
  );
}

export const AppLoader = ({ loader }) => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      {loader}
    </Box>
  );
};

const PyxizSplash = () => {
  const [animate, setAnimate] = useState(true);
  const [animateBox, setAnimateBox] = useState(false);
  setTimeout(() => setAnimateBox(true), 1000);

  const startTimer = useCallback(() => {
    setTimeout(() => {
      setAnimate(false);
      setAnimateBox(false);
    }, 3000);
  }, []);

  return (
    <Fade
      direction="down"
      easing="ease-in-out"
      in={animate}
      appear={false}
      timeout={{ exit: 300 }}
      mountOnEnter
      unmountOnExit
      style={{
        transformOrigin: "0 0 0",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 99999,
      }}
    >
      <Box
        sx={{ height: "100vh", backgroundColor: "#0C0E0D" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        flexDirection="column"
        position={"relative"}
        overflow={"hidden"}
      >
        <img
          // src={top}
          width={"100%"}
          alt=""
          style={{ position: "absolute", right: 0, top: 0 }}
        />
        <img
          // src={bottom}
          alt=""
          width={"100%"}
          style={{ position: "absolute", left: 0, bottom: 0 }}
        />
        <Fade
          direction="down"
          easing="ease-in-out"
          in={animateBox}
          appear={true}
          // timeout={3000}
          timeout={{ exit: 300, enter: 1000, appear: 300 }}
          mountOnEnter
          unmountOnExit
          style={{
            transformOrigin: "0 0 0",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            flexDirection="column"
          >
            <img
              // src={logoSpach}
              alt="pyxiz"
              width={"70%"}
              onLoad={startTimer}
            />
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
};
